* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
.iframeMap{
  max-width: 100%;
  height: 100%;
}
.iframeMap iframe{
  max-width: 100%;
  height: 100%;
}
/* HEADER */
.Header-logo {
  display: block;
  max-width: 210px;
  height: 90px;
  z-index: 3;
  top: 0px;
  bottom: 10px;
  transition: 0.3s ease;
}
.textRed{
  color: #f90000;
}
.textblue{
  color: #214081;
  font-weight: 600;
}
.Header-bottom {
  background: #fff;
  box-sizing: border-box;
  padding: 0 15px;
}

.Header-bottom-container {
  max-width: 1300px;
  width: 100%;
  margin: 0px auto;
  min-height: 97px;
  align-items: center;
  justify-content: flex-end;
}

body .Header-Text {
  text-transform: uppercase;
  color: #214081;
  font-size: 18px;
  font-weight: 600;
  padding: 3px 15px 4px !important;
  text-decoration: none;
}

body .Header-Text:hover {
  color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* Dropdown */
.dropdown-container {
  position: relative;
}

body .sub-menu {
  font-size: 16px;
  display: none;
  position: absolute;
  background: #214081;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
  min-width: 240px;
  line-height: 2;
}

.sub-menu a {
  text-decoration: none;
  padding: 5px 15px;
  width: 100%;
  color: #fff;
  display: block;
}

.sub-menu a:hover {
  background-color: rgba(180, 176, 176, 0.2);

}

.dropdown-container:hover .sub-menu {
  display: block;
}

/* TICKET */
body .ticket {
  background-image: url(./assets/ticket-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  width: 201px;
  height: 81px;
  margin: 0 0 0 27px;
  box-sizing: border-box;
  padding: 0 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  outline: none;
  border: none;
  background-color: transparent;
}
.ticketButtonMain {
  /* color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  width: 25%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  outline: none;
  border: none;
  background-color: red;
  border-radius: 5px; */
  text-transform: uppercase;
  background-color: #FF0000;
  -webkit-border-radius: 10px;
  border-radius: 5px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 20px;
  max-width: 320px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@-webkit-keyframes glowing {
  0% { background-color: #FF0000; -webkit-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -webkit-box-shadow: 0 0 20px #FF0000; }
  100% { background-color: #FF0000; -webkit-box-shadow: 0 0 3px #B20000; }
}

@-moz-keyframes glowing {
  0% { background-color: #FF0000; -moz-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -moz-box-shadow: 0 0 20px #FF0000; }
  100% { background-color: #FF0000; -moz-box-shadow: 0 0 3px #B20000; }
}

@-o-keyframes glowing {
  0% { background-color: #FF0000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 20px #FF0000; }
  100% { background-color: #FF0000; box-shadow: 0 0 3px #B20000; }
}

@keyframes glowing {
  0% { background-color: #FF0000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 20px #FF0000; }
  100% { background-color: #FF0000; box-shadow: 0 0 3px #B20000; }
}
.navbg {
  padding: 0px 0px !important;
}

/* .Carousel-items-set {
  background-size: contain;
} */
.Home-banner .carousel-item {
  object-fit: contain;
}

.Home-banner img {
  height: 100%;
  width: 100%;
}

.Homebanner .carousel-indicators {
  display: none;
}

.carousel-control-next-icon {
  display: none !important;
}

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-indicators {
  display: none !important;
}

.Joinus-section-header {
  margin-bottom: 0px;
}

/* .Joinus-section {
  padding: 15px 53px 10px 0px;
} */
.Joinus-section p {
  margin-bottom: 0px !important;
}

/* .Joinus-section .Joinus-section-header {
  display: inline-block;
  font-size:25px;
  font-weight: 700;
  text-transform: uppercase;
  
} */

/* HIGHT LIGHT SECTION */
.Highlight-Section {
  background: url(./assets/schedule-bg.jpg) no-repeat center/cover fixed;
  padding: 15px 0;
  border-top: 18px solid #214081;
}

.HightlightWidget {
  background: #fff;
  overflow: hidden;
  box-shadow: 2px 1px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #EEF5FF;
}

.HightlightWidget a {
  text-decoration: none;
  color: inherit;
}

.HightlightWidget .Highlight-Header {
  width: 100%;
  text-align: center;
  font-size: 36px;
  background: url(./assets/star-bg.png) repeat center #FFCA40;
  padding: 10px 0 10px;
  margin: 0 0 0px;
}
.HightlightUpcoming{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0px !important;
  padding: 20px;
}

.cardFlexCss{
 padding-right:0px ;
 padding-left:0px ;
 display: flex;
 flex-direction: column;
 background-color: #FFFFFF;
 border-radius: 20px;
}
.HomeCardDate {
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeCardDate span {
  font-size: 14px;
}

.Highlight-Header span {
  margin: 0px 2px;
}

.HightlightWidget .Highlight-Header span {
  display: inline-flex;
  align-items: center;
}

.slick-dots {
  display: none !important;
}

.Highlight-Header img {
  width: 20px;
  height: 20px;
}

.HightlightWidget .card-widget-item {
  display: flex;
  margin: 0 10px 24px 15px;
  min-width: 170px;
  height: 166px;
  position: relative;
  text-decoration: none;
  border-radius: 3px;
}

.cardEventSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.18);
  margin: 10px 20px;
  border-radius: 15px;
  flex-wrap: wrap;
  cursor: pointer;
}

.imgDivUpcoming {
  padding: 0px 0px !important;

}

.cardEventSection img {
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0px 0px !important; 
}

.cardEventContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* .HightlightWidget .card-widget-item:hover .card-widget-title {
  color: #fff !important;
}

.HightlightWidget .card-widget-item:before {
  content: "";
  background: url(./assets/ribbon-blue.png) no-repeat;
  width: 25px;
  height: 39px;
  display: block;
  position: absolute;
  top: -6px;
  left: 10px;
} */

.HightlightWidget .card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 10px 10px 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.18);
}

.HightlightWidget .card-content:hover {
  background: #214081 !important;

}

.HightlightWidget .card-widget-title {
  position: static;
  display: block;
  width: 100%;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 7px;
  font-weight: 600;
}

.Ad-Section {
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  padding: 33px 20px 37px;
}

.AdSectionContainer .card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.AdSectionContainer .card-content img {
  margin: 6px 0 20px;
  max-height: 200px;
  width: 100%;
}

.AdSectionContainer .card-widget-title {
  position: static;
  display: flex;
  width: 100%;
  /* font: 36px "Oswald", Arial, Verdana, sans-serif; */
  font-weight: 600;
  margin: 0 0 17px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.AdSectionContainer .card-widget-description {
  position: static;
  display: block;
  width: 100%;
  font: 18px "RobotoRegular", Arial, Verdana, sans-serif;
  font-weight: 500;
  line-height: 1.6;
}

/* ANIMATION CARDS */
.AnimationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 3px 0 20px;
}

.AnimationContainer .card-widget-item {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 245px;
  overflow: hidden;
  position: relative;
  max-width: 382px;
  border-radius: 8px;
  margin: 0 7px 12px;
}

.AnimationContainer .card-content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  box-sizing: border-box !important;
  padding: 0 10px !important;
  z-index: 1 !important;
}

.AnimationContainer .card-widget-title {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  font: 23px "Oswald", Arial, Verdana, sans-serif;
  color: #214081;
  background: url(./assets/cardtitlebg.png) no-repeat top center;
  padding: 37px 0 26px;
  letter-spacing: -0.6px;
  position: absolute;
  bottom: 0;
  text-transform: uppercase;
}

.AnimationContainer .card-widget-description {
  display: none;
  font: 17px "RobotoRegular", Arial, Verdana, sans-serif;
  color: #fff !important;
  padding: 0px 0 19px;
  color: #505050;
  line-height: 22px;
  letter-spacing: 0px;
}

.AnimationContainer .card-widget-item:hover .card-widget-description {
  display: block;
}

.AnimationContainer .buttonwrap a.moreButton.button {
  padding: 2px 16px 1px !important;
  margin-top: -1px;
}

.AnimationContainer .buttonwrap a {
  font-size: 18px;
  margin: 10px 7.5px 0;
  padding: 3px 19px 1px;
  display: none;
}

.AnimationContainer .card-widget-item:hover .image-container-AD {
  transform: scale(1.4);
}

.AnimationContainer .image-container-AD {
  box-sizing: border-box;
  position: static;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: all 0.5s ease;
}

/* PROMOTION SECTION */
.PromotionSection {
  background: url(./assets/slide-bg.jpg) no-repeat center/cover;
  padding: 56px 15px 55px;
}

.PromotionLeft {
  height: 619px;
}

.PromotionLeft .socialTabContainer {
  background: transparent;
  display: flex;
  padding: 0 0 8px;
}

.socialBg {
  background-color: #214081 !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 5px;
}

.socialBg.active {
  border-radius: 50%;
  border: 3px solid #FA0000 !important;
}

.socialNetworkWidget2 {
  margin-top: 5px;
  background: #fff;
  border-radius: 8px;
  padding: 8px;
}

.IframeWidth {
  width: 400px !important;
}

.email-signup-main {
  background: #214081;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  justify-content: flex-end;
  margin: 50px 0 0;
  position: relative;
  width: 100%;
  min-height: 185px;
  max-height: auto;
}

.email-signup-main:before {
  content: "";
  background: url(./assets/email-signup-graphic.png) no-repeat;
  width: 301px;
  min-height: 211px;
  max-height: auto;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
}

.email-signup-main .emailSignup {
  max-width: 368px;
  display: flex;
  flex-wrap: wrap;
}

.emailInput {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}

.inputFieldTextbox {
  border: 0;
  outline: none;
  height: 41px;
  border-radius: 5px;
  font: 16px "Oswald";
  padding: 0 20px;
}

.GetUpdates {
  min-width: 115px;
  margin: 0 0 0 9px;
  border-radius: 4px !important;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FA0000;
  color: #fff;
  font: 16px "Oswald";
  text-decoration: none;
}


/* Scroll */
.scrollRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
}

.NoSlick {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}



/* MAP SECTION */
.MapSection {
  min-height: 381px;
  display: flex;
  justify-content: center;
  padding: 52px 0 0;
}

.MapSection .map-content {
  width: 352px;
  height: 164px;
  display: flex;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 9px 0 0;
  box-sizing: border-box;
  box-shadow: 2px 1px 4px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

.MapSection .map-content:before {
  content: "";
  background: url(./assets/star.png) no-repeat;
  width: 29px;
  height: 29px;
  display: flex;
  position: absolute;
  bottom: -55px;
  margin-left: 3px;
}

.MapSection .map-content h2 {
  font: 700 26px "Oswald", Arial, Verdana, sans-serif;
  color: #214081;
  margin: 0 0 9px;
}

.MapSection .map-content h2:after {
  content: "";
  width: 30px;
  height: 4px;
  display: block;
  margin: 4px auto 0;
  background: #FA0000;
}

.MapSection .map-content p {
  font: 600 15px "Oswald";
  color: #505050;
  margin: 0;
  text-align: center;
}

.MapSection .map-content a.button {
  font-size: 18px;
  line-height: 1;
  padding: 9px 19px 10px;
  margin: 7px 0 0;
  background: #FA0000;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  font-weight: normal !important;
  color: #fff !important;
}

.MapSection .map-content:after {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #fff;
  font-size: 0;
  line-height: 0;
  margin: 0 auto;
  content: "";
  border-left: 19px solid transparent;
  border-right: 21px solid transparent;
  border-top: 26px solid #fff;
  position: absolute;
  bottom: -18px;
}

/* FOOTER */
.footer {
  position: relative;
  height: auto;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background: url(assets/footer-bg.png) no-repeat transparent top/cover;
  color: #fff;
  text-align: center;
}

.footerContainer {
  padding: 217px 0 0;
}

.footer-info h1 {
  font-size: 20px;
  color: #fff;
  margin: 0;
  padding: 0;
  text-align: left;
}

.footer h1:after {
  background: #f90000;
  content: "";
  width: 30px;
  height: 4px;
  display: block;
  margin: 6px 0 0;
}

.footer-cardinfo h1 {
  font-size: 20px;
  color: #fff;
  margin: 0;
  padding: 0;
  text-align: left;
}

.footer-cardinfo h1:after {
  background: #f90000;
  content: "";
  width: 30px;
  height: 4px;
  display: block;
  margin: 6px 0 0;
}

.footer-cardinfo p {
  line-height: 22px;
  margin-top: 6px;
  letter-spacing: -0.4px;
  font-size: 17px;
  text-align: left;

}

.footer-cardinfo a {
  text-decoration: none;
  color: #FFFFFF;
}

.footer ul {
  list-style-type: none;
  clear: both;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

.footer-info ul {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 10px;
}

.footer-info ul li {
  display: flex;
  margin: 0 0 9px;
  font-size: 15px;
  padding: 0;
  letter-spacing: 0.3px;
  line-height: 26px;
  font-weight: 500;
  font-size: 17px;
}

.footer-info ul li a {
  display: flex;
  font-weight: 500;
  font-size: 17px;
  color: inherit;
  text-decoration: none;

}

.footer-info ul li.tel a:before {
  content: "";
  background: url(./assets/tel.png) no-repeat;
  width: 30px;
  height: 28px;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  color: #000;
  margin: 0 8px 0 0;
}

.footer-info ul li.address:before {
  content: "";
  background: url(./assets/nav.png) no-repeat;
  width: 50px;
  height: 28px;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  color: #000;
  display: flex;
  font-size: 15px;
  margin: 0 8px 0 0;
}

.footer-info ul li.mail a:before {
  content: "";
  background: url(./assets/email.png) no-repeat;
  width: 30px;
  height: 28px;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  color: #000;
  margin: 0 8px 0 0;
}

ul.footer-image {
  max-width: 304px;
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  margin-top: 5px;
}

ul.footer-image li {
  display: inline-flex;
  margin: 3px 0px 0 6px;
  width: 144px;
  height: 144px;
}


.footer-bottom {
  background: #1e3a75;
  padding: 5px 5px 0px 0px;
}

.footer-bottom p {
  font-weight: 500;
  font-size: 17px;
  color: #fff;
}

.Responsive-iframe {
  width: 600px !important;
  height: auto;
}

.marquee-container {
  overflow-x: scroll !important;
}

.next.slick-disabled:before {
  opacity: 1 !important;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.sponsorMain h2 {
  font-weight: 700;
  font-size: 30px;
}

.imgSliderCenter {
  display: flex;
  justify-content: center;
}

.imgSliderCenter img {
  width: 200px !important;
}

.imgSlider img {
  max-width: 200px !important;
  max-height: 115px !important;
}

/* --------------------------------------- */

/* Instagram Feeds */
.mobileIG{
  display: none !important;
}
._wPDyp {
  flex-basis: inherit !important;
  width: 230px !important;
  height: 230px !important;
}
._vzTHL {
  display: block;
  width: 100%;
  height: 100%;
  transition: .25s;
  object-fit: cover;
  backface-visibility: visible !important;
}
._2R-kh {
  justify-content: center !important;
  align-items: center !important;
}

.yTubeContainer iframe {
  width: 100%;
  height: 500px;
}


/* Alert */

.swal2-html-container {
  font-size: 18px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

.swal2-backdrop-show {
  background: rgb(0, 0, 0, 0.9) !important;
}

.eventHeading h1 {
  font-size: 35px;
}

.aboutTitle h2 {
  font-size: 30px;
}

@media (max-width:900px) {
  .Carousel-items-set {
    background-size: contain;
    height: inherit;
  }

  .AnimationContainer .card-widget-title {
    font-size: 18px !important;
  }

  .footer-info h1 {
    font-size: 18px !important;
  }

  .footer-info ul li a {
    font-size: 17px !important;
  }

  .footer-info ul li {
    font-size: 17px !important;
  }

  .footer-cardinfo h1 {
    font-size: 18px !important;

  }

  .footer-cardinfo p {
    font-size: 17px !important;
  }

  .email-signup-main .emailSignup h5 {
    font-size: 18px !important;
  }

  .email-signup-main .emailSignup p {
    font-size: 17px !important;
  }

  .GetUpdates {
    font-size: 17px !important;
  }

  .inputFieldTextbox {
    font-size: 17px !important;
  }



  .aboutTitle h2 {
    font-size: 25px !important;
  }

  .aboutBackContent span {
    font-size: 17px !important;

  }


  .ticketDetailbanner h2 {
    font-size: 20px !important;
  }

  /* 
  .Carousel-items-set {
    height: 200px;
  } */
}

@media (max-width:750px) {
  .desktopIG{
    display: none !important;
  }
  .mobileIG{
    display: block !important;
    margin-bottom: 15px;
  }
  .mobileIGList{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  .Carousel-items-set {
    background-size: contain;
    height: inherit;
  }

  .email-signup-main:before {
    content: "";
    background: none;
    width: 0px;
    min-height: 0px;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .email-signup-main {
    justify-content: center;
  }
}

@media (max-width:600px) {

  .Header-bottom-container {
    min-height: auto;
  }

  .Carousel-items-set {
    background-size: contain;
    height: inherit;
  }

  .AnimationContainer .card-widget-title {
    font-size: 14px !important;
  }

  .AdSectionContainer strong {
    font-size: 20px !important;
  }

  .AdSectionContainer .card-widget-description span {
    font-size: 14px !important;
  }

  .footer-info h1 {
    font-size: 14px !important;
  }

  .footer-info ul li a {
    font-size: 13px !important;
  }

  .footer-info ul li {
    font-size: 13px !important;
  }

  .footer-cardinfo h1 {
    font-size: 14px !important;
  }

  .footer-cardinfo p {
    font-size: 13px !important;
  }
  .cardEventSection img {
    width: 100%;
    border-radius: 20px 20px 0px 0px !important; 
  }
  .email-signup-main .emailSignup h5 {
    font-size: 14px !important;
  }

  .email-signup-main .emailSignup p {
    font-size: 13px !important;
  }

  .GetUpdates {
    position: relative;
    z-index: 999;
    font-size: 13px !important;
    height: 35px !important;
  }

  .inputFieldTextbox {
    font-size: 13px !important;
    height: 35px !important;
  }

  .sponsorMain h2 {
    font-size: 25px !important;
  }

  .aboutTitle h2 {
    font-size: 18px !important;
  }

  .aboutBackContent span {
    font-size: 14px !important;
  }


}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 35em !important;
}